










import {CompletableSimMixin} from '@/tasks/mixins/completable-sim';
import LabSim from '@/tasks/components/simulations/LabSim.vue';
import LabTask from '@/tasks/components/dynamic-questions/labs/LabTask.vue';
import {defineComponent, ref} from '@vue/composition-api';
import {useAuthService} from '@/auth/services/authService';
import {useInertia} from '@/inertia/plugins/inertia';

export default defineComponent({
  name: 'FreezingPointDepressionUpdated',
  components: {LabSim, LabTask},
  mixins: [CompletableSimMixin()],
  setup() {
    let seed = ref<number | null>(useInertia().getSeed());

    return {seed};
  },
});
